<template>
  <b-card title="Estados del motorizado">
    <app-timeline v-if="show">
      <app-timeline-item v-for="estado in estadosData" :key="estado.fecha" :icon="stateIcon(estado.codigo)"
        :variant="stateColor(estado.codigo)">
        <div class="
              d-flex
              flex-sm-row flex-column flex-wrap
              justify-content-between
              mb-1 mb-sm-0
            ">
          <h6 class="m-0">{{ estado.descripcion }}</h6>
        </div>
        <small>{{ formattedDate(estado.fecha) }}</small>
      </app-timeline-item>
    </app-timeline>
    <b-alert v-else show variant="warning" class="full-width">
      <div class="alert-title text-center pt-25">
        <feather-icon class="mt-25" icon="AlertTriangleIcon" size="30" />
      </div>
      <div class="alert-body text-center">
        <span class="ml-25">
          No hay estados de motorizado
        </span>
      </div>
    </b-alert>
  </b-card>
</template>

<script>
import { BCard, BAlert } from "bootstrap-vue";
import moment from "moment";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
  components: {
    BCard,
    BAlert,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    estadosData: {
      type: Array,
      required: false,
    },
  },
  methods: {
    formattedDate(originalDate) {
      return moment(originalDate).format("DD/MM/YYYY HH:mm a");
    },
    stateIcon(state) {
      switch (state) {
        case "CR":
          return "UserPlusIcon";
        case "CL":
          return "DownloadIcon";
        case "SP":
          return "UploadIcon";
        case "LD":
          return "MapPinIcon";
        case "ET":
          return "CheckCircleIcon";
        case "PN":
        case "NE":
          return "XCircleIcon";
        default:
          return "CheckCircleIcon";
      }
    },
    stateColor(state) {
      switch (state) {
        case "PN":
        case "NE":
          return "danger";
        default:
          return "success";
      }
    },
  }, computed: {
    show() {
      if (this.estadosData &&
        this.estadosData.length > 0) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style></style>
