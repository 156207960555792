var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": !_vm.ordenData && !_vm.loading
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Error obteniendo datos")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" No se encontró la orden solicitada, revise el "), _c('b-link', {
    staticClass: "alert-link",
    attrs: {
      "to": {
        name: 'ordenes'
      }
    }
  }, [_vm._v(" listado de ordenes ")]), _vm._v(" para ver otras ordenes ")], 1)]), _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm"
    }
  }, [!_vm.loading && _vm.ordenData !== null ? [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12",
      "xl": "4",
      "lg": "6",
      "md": "6"
    }
  }, [_c('orden-card', {
    attrs: {
      "orden-data": _vm.ordenData,
      "callback-order": _vm.getOrderData
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "xl": "4",
      "lg": "6",
      "md": "6"
    }
  }, [_c('cliente-card', {
    attrs: {
      "orden-data": _vm.ordenData
    }
  })], 1), !_vm.isAdministrator && !_vm.isManager && !_vm.isCajero ? _c('b-col', {
    attrs: {
      "cols": "12",
      "xl": "4",
      "lg": "6",
      "md": "6"
    }
  }, [_c('device-card', {
    attrs: {
      "orden-data": _vm.ordenData
    }
  })], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12",
      "xl": "4",
      "lg": "6",
      "md": "6"
    }
  }, [_c('estado-orden-card', {
    attrs: {
      "orden-id": _vm.ordenData.idorden,
      "estados-data": _vm.ordenData.lstEstadosOrden,
      "callback-order": _vm.getOrderData,
      "facturas": _vm.ordenData.lstFacturaPago,
      "tiempo-preparacion": _vm.ordenData.tiempopreparacion,
      "current-state": _vm.ordenData.estadoorden
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "xl": "4",
      "lg": "6",
      "md": "6"
    }
  }, [_c('motorizado-card', {
    attrs: {
      "show": _vm.ordenData.lstRepartidor && _vm.ordenData.lstRepartidor.length > 0,
      "url-gacela": _vm.ordenData.tracking_gacela,
      "motorizado-data": _vm.ordenData.lstRepartidor[0]
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "xl": "4",
      "lg": "6",
      "md": "6"
    }
  }, [_c('estado-motorizado-card', {
    attrs: {
      "estados-data": _vm.ordenData.lstEstadosMotorizado
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('productos-card', {
    attrs: {
      "order-id": _vm.ordenData.idorden,
      "order-state": _vm.ordenData.estadoorden,
      "driver-states": _vm.ordenData.lstEstadosMotorizado,
      "productos-data": _vm.ordenData.lstProductos,
      "commerce-type": _vm.ordenData.lineadenegocio
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('detalles-card', {
    attrs: {
      "orden-data": _vm.ordenData
    }
  })], 1)], 1)] : _c('div', {
    staticStyle: {
      "text-align": "center",
      "margin": "45vh 0"
    }
  })], 2), _c('b-modal', {
    ref: "modal-error",
    attrs: {
      "id": "modal-error",
      "title": "Error",
      "ok-only": "",
      "centered": "",
      "ok-title": "Accept",
      "ok-variant": "danger",
      "modal-class": "modal-danger"
    }
  }, [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.error) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }