<template>
  <b-card title="Datos del Motorizado">
    <div v-if="show">
      <b-row>
        <b-col xl="4" cols="21" class="d-flex justify-content-between flex-column">
          <div class="d-flex" style="flex-direction: column">
            <b-avatar :src="motorizadoData.fotorepartidor" size="100px" style="align-self: center" rounded />

          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col cols="12" xl="8">

          <b-col cols="12" class="p-0">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-secondary" size="30">
                  <feather-icon icon="UserIcon" size="15" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <small>Nombres</small>
                <p class="mhj">
                  {{ motorizadoData.nommbrerepartidor }}
                </p>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col cols="12" class="p-0">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-secondary" size="30">
                  <feather-icon icon="UserIcon" size="15" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <small>Apellidos</small>
                <p class="mhj">
                  {{ motorizadoData.apellidorepartidor }}
                </p>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col cols="12" class="p-0">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-secondary" size="30">
                  <feather-icon icon="CreditCardIcon" size="15" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <small>Identificación</small>
                <p class="mhj">
                  {{ motorizadoData.indentificadorrepartidor }}
                </p>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col cols="12" class="p-0">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-secondary" size="30">
                  <feather-icon icon="FlagIcon" size="15" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <small>Placa</small>
                <p class="mhj">
                  {{ motorizadoData.placarepartidor }}
                </p>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col cols="12" class="p-0">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-secondary" size="30">
                  <feather-icon icon="PhoneIcon" size="15" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <small>Teléfono</small>
                <p class="mhj">
                  {{ motorizadoData.telefonorepartidor }}
                </p>
              </b-media-body>
            </b-media>
          </b-col>


        </b-col>
      </b-row>

      <b-button class="mt-2 full-width" variant="primary" @click="onclickShowMap">
        Ver mapa
      </b-button>

    </div>
    <b-alert v-else show variant="warning" class="full-width">
      <div class="alert-title text-center pt-25">
        <feather-icon class="mt-25" icon="AlertTriangleIcon" size="30" />
      </div>
      <div class="alert-body text-center">
        <span class="ml-25">
          No hay motorizado asignado
        </span>
      </div>
    </b-alert>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAlert,
  BMedia,
  BAvatar,
  BButton,
  BMediaBody,
  BMediaAside,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BMedia,
    BAlert,
    BAvatar,
    BButton,
    BMediaBody,
    BMediaAside,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    urlGacela: {
      type: String,
      default: "",
    },
    motorizadoData: {
      type: Object,
    },
  },
  methods: {
    onclickShowMap(e) {
      e && e.preventDefault();
      window.open(this.urlGacela, "_blank").focus();
    },
  },
};
</script>

<style>
.mhj {
  margin: 0;
  font-size: 12px;
  line-height: 14px !important;
  font-weight: bold;
}

.datos-cliente .media {
  margin-bottom: 8px;
}
</style>
