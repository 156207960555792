var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.comercio !== 'MARKET' ? _c('b-card', {
    attrs: {
      "title": _vm.factura === '' ? 'Preparar la orden' : 'Actualizar'
    }
  }, [_c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "variant": "danger",
      "show": true
    }
  }, [_vm.factura === '' ? _c('div', {
    staticClass: "alert-body"
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "InfoIcon"
    }
  }), _vm._v(" No has registrado el número de factura ")], 1) : _vm._e()]), _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "block": "",
      "variant": "primary"
    },
    on: {
      "click": _vm.showPreparacionModal
    }
  }, [_vm._v(" " + _vm._s(_vm.factura === '' ? 'Iniciar Preparacion' : 'Actualizar Factura') + " ")]), _c('preparacion-modal', {
    attrs: {
      "show": _vm.showModal,
      "hide": _vm.hidePreparacionModal,
      "orden-id": _vm.ordenId,
      "callback": _vm.callbackOrder,
      "orden-tiempo-preparacion": _vm.ordenData.tiempopreparacion
    }
  }), _c('b-modal', {
    attrs: {
      "id": "modal-factura",
      "size": "lg",
      "title": "Actualizar factura",
      "centered": "",
      "hide-footer": ""
    },
    model: {
      value: _vm.showActFactura,
      callback: function callback($$v) {
        _vm.showActFactura = $$v;
      },
      expression: "showActFactura"
    }
  }, [_c('factura-modal', {
    attrs: {
      "ordenId": _vm.ordenId,
      "factura": _vm.factura
    }
  })], 1)], 1) : _vm.comercio === 'MARKET' && (_vm.estado === 'C' || _vm.estado === 'IN') ? _c('b-card', {
    attrs: {
      "title": "Anular orden"
    }
  }, [_c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "variant": "warning",
      "show": true
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "InfoIcon"
    }
  }), _vm._v(" La orden ya fue pickeada ")], 1)]), _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "block": "",
      "variant": "primary",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.cancelOrder
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Anular orden")])], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }