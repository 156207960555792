<template>
  <b-badge variant="secondary" v-if="isDarkStoreCancelable">
    <div v-if="isLoading">
      <b-spinner small type="border" variant="primary" />
      Verificando Darkstore...
    </div>
    <div id="error-tooltip-target" v-else-if="error">
      <b-spinner small type="grow" variant="warning" />
      Error al verificar Darkstore
      <b-tooltip target="error-tooltip-target" triggers="hover">
        {{ error }}
      </b-tooltip>
    </div>
    <div v-else-if="isDarkStoreCanceled">
      <b-spinner small type="grow" variant="success" />
      Orden cancelada en Darkstore
    </div>
    <div v-else>
      <b-spinner small type="grow" variant="danger" />
      Orden no cancelada en Darkstore
    </div>
  </b-badge>
</template>

<script>
import { BBadge, BSpinner, BTooltip } from "bootstrap-vue";
import { getAuthToken } from "@/auth/utils";

export default {
  components: {
    BBadge,
    BSpinner,
    BTooltip
  },
  props: {
    code: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      isDarkStoreCancelable: false,
      isDarkStoreCanceled: false,
      error: null
    };
  },
  created() {
    this.getIsDarkStoreCancelable(this.code);
    this.getIsDarkStoreCanceled(this.id);
  },
  methods: {
    getIsDarkStoreCancelable(code) {
      this.isDarkStoreCancelable = code === "AN";
    },
    getIsDarkStoreCanceled(id) {
      this.isLoading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/order/GetIsDarkStoreOrderCanceled",
          body: JSON.stringify({
            order: id,
            tokenSesion: getAuthToken(),
          })
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.bOk) {
            this.isDarkStoreCanceled = res.data;
          } else {
            this.error = res.data.mensaje;
          }
        })
        .catch((e) => {
          this.isLoading = false;
          this.error = e.message;
        });
    }
  }
};
</script>
