<template>
    <b-card title="Datos del Dispositivo" class="datos-cliente">
      <b-row>
        <b-col cols="12">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-secondary" size="30">
                <feather-icon icon="SmartphoneIcon" size="15" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <small>Modelo</small>
              <p class="mhj">
                {{ getDevice }}
              </p>
            </b-media-body>
          </b-media>
        </b-col>
  
        <b-col cols="12">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-secondary" size="30">
                <feather-icon icon="LayersIcon" size="15" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <small>Plataforma</small>
              <p class="mhj">
                {{ getDeviceType }}
              </p>
            </b-media-body>
          </b-media>
        </b-col>
  
        <b-col cols="12">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-secondary" size="30">
                <feather-icon icon="GitPullRequestIcon" size="15" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <small>Version aplicativo</small>
              <p class="mhj">
                {{ getVersion }}
              </p>
            </b-media-body>
          </b-media>
        </b-col>
  
        <b-col cols="12">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-secondary" size="30">
                <feather-icon icon="CpuIcon" size="15" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <small>Version Sistema Operativo</small>
              <p class="mhj">
                {{ getOSVersion }}
              </p>
            </b-media-body>
          </b-media>
        </b-col>
  
       
      </b-row>
    </b-card>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BCard,
    BMedia,
    BBadge,
    BButton,
    BAvatar,
    BMediaBody,
    BMediaAside,
  } from "bootstrap-vue";
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BMedia,
      BBadge,
      BButton,
      BAvatar,
      BMediaBody,
      BMediaAside,
    },
    props: {
      ordenData: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
      }
    },
    created() {
      
    },
    computed: {
      getDevice() {
        if (this.ordenData.origen === 3) { //RAPPI
          return ''
        }
        return this.ordenData.device;
      },
      getDeviceType() {
        if (this.ordenData.origen === 3) { //RAPPI
          return ''
        }
        return this.ordenData.typeDevice;
      },
      getVersion() {
        if (this.ordenData.origen === 3) { //RAPPI
          return ''
        }
        return this.ordenData.version;
      },
      getOSVersion() {
        if (this.ordenData.origen === 3) { //RAPPI
          return ''
        }
        return this.ordenData.osVersion;
      },
    },
  };
  </script>
  
  <style>
  .mhj {
    margin: 0;
    font-size: 12px;
    line-height: 14px !important;
    font-weight: bold;
  }
  .datos-cliente .media {
    margin-bottom: 8px;
  }
  </style>
  