var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ordenData !== null && _vm.ordenData !== undefined ? _c('b-card-group', {
    staticClass: "mb-0",
    attrs: {
      "deck": ""
    }
  }, [_c('b-card', {
    staticClass: "card-apply-job",
    attrs: {
      "title": _vm.ordenData.empresaasociada
    }
  }, [_c('div', {
    staticClass: "apply-job-package bg-light-primary rounded m-0"
  }, [_c('h2', {
    staticClass: "m-0"
  }, [_vm._v(" Orden ")]), _c('h2', {
    staticClass: "m-0"
  }, [_c('sup', {
    staticClass: "text-body"
  }, [_c('small', [_vm._v("#")])]), _vm._v(_vm._s(_vm.ordenData.idorden) + " ")])]), _vm.ordenData.numero_orden_se1 ? _c('div', {
    staticClass: "apply-job-package bg-light-secondary rounded m-0"
  }, [_c('strong', {
    staticClass: "m-0"
  }, [_vm._v(" Orden Super Easy 1: " + _vm._s(_vm.ordenData.numero_orden_se1) + " ")])]) : _vm._e(), _c('b-media', {
    staticClass: "mt-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-primary",
      "size": "34"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon",
      "size": "18"
    }
  })], 1)], 1), _c('b-media-body', [_c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(" Fecha de creación ")]), _c('small', [_vm._v(" " + _vm._s(_vm.formattedDate(_vm.ordenData.fechacreacion)) + " - " + _vm._s(_vm.formattedHour(_vm.ordenData.fechacreacion)))])])], 1), _c('b-media', {
    staticClass: "mt-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-primary",
      "size": "34"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CrosshairIcon",
      "size": "18"
    }
  })], 1)], 1), _c('b-media-body', [_c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(" Linea de negocio ")]), _c('small', [_vm._v(" " + _vm._s(_vm.ordenData.lineadenegocio) + " ")])])], 1), _c('b-media', {
    staticClass: "mt-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-primary",
      "size": "34"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SendIcon",
      "size": "18"
    }
  })], 1)], 1), _c('b-media-body', [_c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(" Tipo de entrega ")]), _c('small', [_vm.ordenData.tipoentrega === 'PG' ? _c('b-badge', {
    attrs: {
      "variant": "light-warning"
    }
  }, [_c('small', {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(" Programada ")])]) : _vm.ordenData.tipoentrega === 'SA' ? _c('b-badge', {
    attrs: {
      "variant": "light-info"
    }
  }, [_c('small', {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(" Sin Apuros ")])]) : _c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_c('small', {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(" Inmediata ")])]), _vm.ordenData.tipoentrega === 'PG' ? _c('b-badge', {
    staticClass: "ml-1"
  }, [_c('small', [_vm._v(" " + _vm._s(_vm.formattedDate(_vm.ordenData.fechaprogramada)) + " " + _vm._s(_vm.formattedHour(_vm.ordenData.fechaprogramada)) + " ")])]) : _vm._e()], 1)])], 1), _vm.ordenData.prTipo != null ? _c('b-media', {
    staticClass: "mt-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-primary",
      "size": "34"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "GiftIcon",
      "size": "18"
    }
  })], 1)], 1), _c('b-media-body', [_c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(" Promoción (" + _vm._s(_vm.getPromoType) + ") ")]), _c('small', [_vm._v(" " + _vm._s(_vm.ordenData.prNombre) + " ")])])], 1) : _vm._e(), _vm.isSuperUser() ? _c('b-button', {
    staticClass: "full-width mt-1",
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": _vm.onSendGacela
    }
  }, [_vm._v(" Reenviar a Gacela ")]) : _vm._e(), _vm.ordenData.estadoorden === 'PG' ? _c('b-button', {
    staticClass: "mt-1 full-width",
    attrs: {
      "block": "",
      "variant": "danger"
    },
    on: {
      "click": _vm.onClickCancelar
    }
  }, [_vm._v("Cancelar orden")]) : _vm._e()], 1), _c('preparacion-cerrar-card', {
    attrs: {
      "estado": _vm.ordenData.estadopicking,
      "factura": _vm.ordenData.lstFacturaPago[0].factura_empresa_asociada,
      "orden-id": _vm.ordenData.idorden,
      "comercio": _vm.ordenData.lineadenegocio,
      "callback-order": _vm.callbackOrder,
      "orden-tiempo-preparacion": _vm.ordenData.tiempopreparacion
    }
  }), _c('cancelar-modal', {
    attrs: {
      "show": _vm.showModalCancelar,
      "callback": _vm.callback,
      "orden-id": _vm.ordenData.idorden
    },
    on: {
      "hide": _vm.hideCancelarModal
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }