<template>
  <b-card-group deck class="mb-0" v-if="ordenData !== null && ordenData !== undefined">
    <b-card :title="ordenData.empresaasociada" class="card-apply-job">
      <div class="apply-job-package bg-light-primary rounded m-0">
        <h2 class="m-0">
          Orden
        </h2>
        <h2 class="m-0">
          <sup class="text-body"> <small>#</small> </sup>{{ ordenData.idorden }}
        </h2>
      </div>
      <div class="apply-job-package bg-light-secondary rounded m-0" v-if="ordenData.numero_orden_se1">
        <strong class="m-0">
          Orden Super Easy 1: {{ ordenData.numero_orden_se1 }}
        </strong>
      </div>

      <b-media class="mt-1" no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="CalendarIcon" size="18" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            Fecha de creación
          </h6>
          <small> {{ formattedDate(ordenData.fechacreacion) }} - {{ formattedHour(ordenData.fechacreacion) }}</small>
        </b-media-body>
      </b-media>


      <b-media class="mt-1" no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="CrosshairIcon" size="18" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            Linea de negocio
          </h6>
          <small> {{ ordenData.lineadenegocio }} </small>
        </b-media-body>
      </b-media>


      <b-media class="mt-1" no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="SendIcon" size="18" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            Tipo de entrega
          </h6>
          <small>
            <b-badge v-if="ordenData.tipoentrega === 'PG'" variant="light-warning">
              <small style="font-weight: bold">
                Programada
              </small>
            </b-badge>
            <b-badge v-else-if="ordenData.tipoentrega === 'SA'" variant="light-info">
              <small style="font-weight: bold">
                Sin Apuros
              </small>
            </b-badge>
            <b-badge v-else variant="light-success">
              <small style="font-weight: bold">
                Inmediata
              </small>
            </b-badge>

            <b-badge v-if="ordenData.tipoentrega === 'PG'" class="ml-1">
              <small>
                {{ formattedDate(ordenData.fechaprogramada) }}
                {{ formattedHour(ordenData.fechaprogramada) }}
              </small>
            </b-badge>

          </small>
        </b-media-body>
      </b-media>

      <b-media v-if="ordenData.prTipo != null" class="mt-1" no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="GiftIcon" size="18" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            Promoción ({{ getPromoType }})
          </h6>
          <small> {{ ordenData.prNombre }} </small>
        </b-media-body>
      </b-media>


      <b-button v-if="isSuperUser()" @click="onSendGacela" variant="warning" class="full-width mt-1">
        Reenviar a Gacela
      </b-button>

      <b-button v-if="ordenData.estadoorden === 'PG'" block class="mt-1 full-width" @click="onClickCancelar"
        variant="danger">Cancelar orden</b-button>
    </b-card>


    <preparacion-cerrar-card :estado="ordenData.estadopicking"
      :factura="ordenData.lstFacturaPago[0].factura_empresa_asociada" :orden-id="ordenData.idorden"
      :comercio="ordenData.lineadenegocio" :callback-order="callbackOrder"
      :orden-tiempo-preparacion="ordenData.tiempopreparacion" />

    <cancelar-modal :show="showModalCancelar" @hide="hideCancelarModal" :callback="callback"
      :orden-id="ordenData.idorden" />
  </b-card-group>
</template>

<script>
import {
  BCard,
  BBadge,
  BMedia,
  BAvatar,
  BButton,
  BCardGroup,
  BMediaBody,
  BMediaAside,
} from "bootstrap-vue";
import moment from "moment";
import CancelarModal from "./CancelarModal.vue";
import PreparacionCerrarCard from "./PreparacionCerrarCard.vue";
import { isSuperUser, getAuthToken } from "@/auth/utils";

export default {
  components: {
    BCard,
    BBadge,
    BMedia,
    BButton,
    BAvatar,
    BCardGroup,
    BMediaBody,
    BMediaAside,
    CancelarModal,
    PreparacionCerrarCard,
  },
  props: {
    callbackOrder: {
      type: Function,
      required: true,
    },
    ordenData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { showModalCancelar: false };
  },
  methods: {
    isSuperUser,
    callback() {
      this.callbackOrder();
      this.hideCancelarModal();
    },
    onClickCancelar(e) {
      e.preventDefault();
      this.showModalCancelar = true;
    },
    onSendGacela(e) {
      e.preventDefault();
      const GacelaReintento = {
        ordenId: this.ordenData.idorden,
        tokenSesion: getAuthToken(),
      }
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Gacela/ReintentoOrdenGacela",
          body: JSON.stringify(GacelaReintento),
        })
        .then((res) => {
          // this.loading = false;
          if (res.data.bOk) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "CheckIcon",
                text: res.data.mensaje,
                title: "Exito",
                variant: "success",
              },
            });
          } else {
            this.error = res.data.mensaje;
            this.showErrorModal = true;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.error = e.message;
          this.showErrorModal = true;
        });

    },
    hideCancelarModal() {
      this.showModalCancelar = false;
    },
    formattedDate(originalDate) {
      return moment(new Date(originalDate)).format("DD/MM/YYYY");
    },
    formattedHour(originalDate) {
      return moment(new Date(originalDate)).format("hh:mm a");
    },
  },
  computed: {
    getPromoType() {
      switch (this.ordenData.prTipo) {
        case "PROCO":
          return "Promocode";
        case "PROTA":
          return "Promotarjeta";
        default:
          return "Promotarjeta";
      }
    },
  },
};
</script>

<style></style>
