var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isDarkStoreCancelable ? _c('b-badge', {
    attrs: {
      "variant": "secondary"
    }
  }, [_vm.isLoading ? _c('div', [_c('b-spinner', {
    attrs: {
      "small": "",
      "type": "border",
      "variant": "primary"
    }
  }), _vm._v(" Verificando Darkstore... ")], 1) : _vm.error ? _c('div', {
    attrs: {
      "id": "error-tooltip-target"
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow",
      "variant": "warning"
    }
  }), _vm._v(" Error al verificar Darkstore "), _c('b-tooltip', {
    attrs: {
      "target": "error-tooltip-target",
      "triggers": "hover"
    }
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")])], 1) : _vm.isDarkStoreCanceled ? _c('div', [_c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow",
      "variant": "success"
    }
  }), _vm._v(" Orden cancelada en Darkstore ")], 1) : _c('div', [_c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow",
      "variant": "danger"
    }
  }), _vm._v(" Orden no cancelada en Darkstore ")], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }