<template>
    <div>
        <b-button class="full-width mt-1" variant="primary" v-b-modal.modal-iniciarP>
            <b-spinner v-if="loading" small />
            <small v-else>Iniciar Preparación / Ingresar
                Factura</small></b-button>
        <b-modal id="modal-iniciarP" title="Iniciar preparación" @ok="handleOk" ok-title="Guardar"
            cancel-title="Cancelar">
            <b-row>
                <b-col cols="12">
                    <h3 class="mb-2 text-center text-bold">¿Esta seguro de preparar la orden?</h3>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="mb-1">
                    <h5 class="mb-0">Ingrese el número de factura</h5>
                </b-col>
                <b-col cols="12" lg="3">
                    <b-form-input id="facturaIn1" type="text" placeholder="000" v-model="factura1"
                        :formatter="(s) => formatter(s, 3)" />
                </b-col>
                <b-col cols="12" lg="3">
                    <b-form-input id="facturaIn2" type="text" placeholder="000" v-model="factura2"
                        :formatter="(s) => formatter(s, 3)" />
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-input id="facturaIn3" type="text" placeholder="000000000" v-model="factura3"
                        :formatter="(s) => formatter(s, 9)" />
                </b-col>
            </b-row>
            <b-row class="mt-2 mb-1">
                <b-col cols="12" class="mb-1">
                    <h5 class="mb-0 text-center">Tiempo de preparación de la orden</h5>
                </b-col>
                <b-col cols="12" style="display: flex;justify-content: center;">
                    <b-badge variant="dark">
                        <h4 style="color: white;" class="m-0"> {{ tiempo }} Minutos </h4>
                    </b-badge>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BBadge,
    BButton,
    BSpinner,
    BFormInput,
} from "bootstrap-vue";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BRow,
        BCol,
        BBadge,
        BButton,
        BSpinner,
        BFormInput,
    },
    props: {
        ordenId: {
            type: Number,
            required: true,
        },
        numeroFactura: {
            type: String,
            default: "",
        },
        tiempoPreparacion: {
            type: String,
        },
        callback: {
            type: Function,
            default: () => { },
        }
    },
    data() {
        return {
            tiempo: Number(this.tiempoPreparacion) ?? 15,
            loading: false,
            factura1: "000",
            factura2: "000",
            factura3: "000000000",
        };
    },
    beforeMount() {
        if (this.numeroFactura !== "") {
            const splitted = this.numeroFactura.split("-");
            if (splitted.length > 2) {
                this.factura1 = splitted[0];
                this.factura2 = splitted[1];
                this.factura3 = splitted[2];
            }
        }
    },
    methods: {
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault();
            this.$nextTick(() => {
                this.$bvModal.hide("modal-iniciarP");
                const factura = `${this.factura1}-${this.factura2}-${this.factura3}`;
                this.loading = true;
                this.$http
                    .post(this.$store.state.app.middlewareURL, {
                        path: "/Customer/ActualizaEstadoOrden",
                        body: JSON.stringify({
                            opt: "FTE",
                            empresa: 2,
                            idOrden: this.ordenId,
                            factura: factura,
                            tokenSesion: getAuthToken(),
                            tiempoTienda: false,
                            tiempoPreparacion: this.tiempo,
                        }),
                    })
                    .then((res) => {
                        this.loading = false;
                        if (res.data.bOk) {
                            this.callback();
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Éxito",
                                    icon: "CheckIcon",
                                    text: "Se cambio correctamente el estado de la orden.",
                                    variant: "success",
                                },
                            });
                        } else {
                            throw new Error(res.data.mensaje);
                        }
                    })
                    .catch((e) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Error",
                                icon: "AlertTriangleIcon",
                                text: `Ocurrió un error al cambiar el estado de la orden(${e.message}).`,
                                variant: "danger",
                            },
                        });
                        this.loading = false;
                    });
            });
        },
        formatter(value, length) {
            const regExp = /[a-zA-Z]/g;
            value = value.replace(regExp, "");
            if (value.length > length) return value.substring(1);
            else if (value.length === length) return value;
            else return "0" + value;
        },
    },
};
</script>

<style lang="scss"></style>