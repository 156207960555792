var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "datos-cliente",
    attrs: {
      "title": "Datos del Dispositivo"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SmartphoneIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('small', [_vm._v("Modelo")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.getDevice) + " ")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "LayersIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('small', [_vm._v("Plataforma")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.getDeviceType) + " ")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "GitPullRequestIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('small', [_vm._v("Version aplicativo")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.getVersion) + " ")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CpuIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('small', [_vm._v("Version Sistema Operativo")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.getOSVersion) + " ")])])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }