<template>
  <div>
    <b-alert variant="danger" :show="!ordenData && !loading">
      <h4 class="alert-heading">Error obteniendo datos</h4>
      <div class="alert-body">
        No se encontró la orden solicitada, revise el
        <b-link class="alert-link" :to="{ name: 'ordenes' }">
          listado de ordenes
        </b-link>
        para ver otras ordenes
      </div>
    </b-alert>

    <b-overlay :show="loading" rounded="sm">
      <template v-if="!loading && ordenData !== null">
        <b-row>
          <b-col cols="12" xl="4" lg="6" md="6" class="mb-2">
            <orden-card :orden-data="ordenData" :callback-order="getOrderData" />
          </b-col>

          <b-col cols="12" xl="4" lg="6" md="6">
            <cliente-card :orden-data="ordenData" />
          </b-col>

          <b-col v-if="!isAdministrator && !isManager && !isCajero" cols="12" xl="4" lg="6" md="6">
            <device-card :orden-data="ordenData" />
          </b-col>

          <!-- <b-col cols="12" xl="4" lg="4">
            <picking-card :orden-data="ordenData" />
          </b-col> -->

          <b-col cols="12" xl="4" lg="6" md="6">
            <estado-orden-card :orden-id="ordenData.idorden" :estados-data="ordenData.lstEstadosOrden"
              :callback-order="getOrderData" :facturas="ordenData.lstFacturaPago"
              :tiempo-preparacion="ordenData.tiempopreparacion" :current-state="ordenData.estadoorden" />
          </b-col>

          <b-col cols="12" xl="4" lg="6" md="6">
            <motorizado-card :show="ordenData.lstRepartidor && ordenData.lstRepartidor.length > 0"
              :url-gacela="ordenData.tracking_gacela" :motorizado-data="ordenData.lstRepartidor[0]" />
          </b-col>

          <b-col cols="12" xl="4" lg="6" md="6">
            <estado-motorizado-card :estados-data="ordenData.lstEstadosMotorizado" />
          </b-col>

          <!-- Products List -->
          <b-col cols="12" md="8">
            <productos-card :order-id="ordenData.idorden" :order-state="ordenData.estadoorden"
              :driver-states="ordenData.lstEstadosMotorizado" :productos-data="ordenData.lstProductos"
              :commerce-type="ordenData.lineadenegocio" />
          </b-col>

          <b-col cols="12" md="4">
            <detalles-card :orden-data="ordenData" />
          </b-col>
        </b-row>
      </template>
      <div style="text-align: center; margin: 45vh 0" v-else></div>
    </b-overlay>

    <b-modal id="modal-error" ref="modal-error" title="Error" ok-only centered ok-title="Accept" ok-variant="danger"
      modal-class="modal-danger">
      <b-card-text>
        {{ error }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BForm,
  BAlert,
  BButton,
  BOverlay,
  BSpinner,
  BCardText,
  BFormInput,
  BFormGroup,
} from "bootstrap-vue";
import {
  isCajero,
  isManager,
  getAuthToken,
  isAdministrator,
} from "@/auth/utils";
import OrdenCard from "./components/OrdenCard.vue";
import DeviceCard from "./components/DeviceCard.vue";
import ClienteCard from "./components/ClienteCard.vue";
import DetallesCard from "./components/DetallesCard.vue";
import ProductosCard from "./components/ProductosCard.vue";
import MotorizadoCard from "./components/MotorizadoCard.vue";
import EstadoOrdenCard from "./components/EstadoOrdenCard.vue";
import EstadoMotorizadoCard from "./components/EstadoMotorizadoCard.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BForm,
    BCard,
    BAlert,
    BButton,
    BSpinner,
    BOverlay,
    BCardText,
    OrdenCard,
    BFormInput,
    BFormGroup,
    DeviceCard,
    ClienteCard,
    DetallesCard,
    ProductosCard,
    MotorizadoCard,
    EstadoOrdenCard,
    EstadoMotorizadoCard,
  },
  data() {
    return {
      error: "",
      socket: null,
      orderID: null,
      loading: true,
      ordenData: null,
      tiempoPreparacion: 10,
    };
  },
  mounted() {
    if (this.$route.params.orden) {
      this.loading = false;
      this.socketConnection();
    } else {
      this.orderID = parseInt(this.$route.path.replace("/orden/", ""));
      this.getOrderData();
    }
  },
  methods: {
    getOrderData() {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/OrdenesCallCenter",
          body: JSON.stringify({
            tokenSesion: getAuthToken(),
            Filas: 1,
            Pagina: 1,
            Estado: "",
            TipoOrden: "",
            TipoCliente: "",
            BusquedaAll: "",
            IDOrden: this.orderID,
            IDEmpresaAsociada: 0,
            IDBodega: 0,
            IDCliente: 0,
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.ordenData = res.data.lstOrdenes[0];
            this.socketConnection();
          } else {
            this.error = res.data.mensaje;
            this.$refs["modal-error"].show();
          }
          this.loading = false;
        })
        .catch((e) => {
          this.error = e.message;
          this.$refs["modal-error"].show();
          this.loading = false;
        });
    },
    socketConnection() {
      if (this.socket === null) {
        this.socket = new WebSocket("wss://store3.supereasy.ec:10030");
        this.socket.onopen = this.onSocketOpen;
        this.socket.onclose = this.onSocketClose;
        this.socket.onmessage = this.onNewNotification;
      }
    },
    onSocketOpen(event) {
      this.sendMessage(
        JSON.stringify({
          event: "/ORDER/STATE",
          payload: { orderID: this.orderID },
        })
      );
    },
    onSocketClose(event) {
      if (event.code === 3999) {
      } else {
        this.socket = null;
        this.retryTimeout = setTimeout(this.socketConnection, 10000);
      }
    },
    onNewNotification(event) {
      const message = JSON.parse(event.data);
      const newOrder = message.payload;
      this.ordenData = newOrder;
      this.$toast({
        component: ToastificationContent,
        props: {
          icon: "BellIcon",
          title: "Nuevo estado de la Orden",
          draggable: true,
          closeButton: "button",
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true,
          draggablePercent: 0.6,
          pauseOnFocusLoss: true,
          showCloseButtonOnHover: false,
        },
      });
    },
    sendMessage(message) {
      this.socket.send(message);
    },
  },
  computed: {
    isCajero,
    isManager,
    isAdministrator,
  },
};
</script>
