<template>
  <div>
    <b-card v-if="!!dataEstados && dataEstados.length > 0">
      <template #header>
        <h4 class="mb-0">Estados de la Orden</h4>
      </template>

      <app-timeline>
        <app-timeline-item v-for="estado in dataEstados" :key="estado.fecha" :icon="stateIcon(estado.codigo)"
          :variant="stateColor(estado.codigo)">
          <div class="
              d-flex
              flex-sm-row flex-column flex-wrap
              justify-content-between
              mb-1 mb-sm-0
            ">
            <h6 class="m-0">{{ estado.descripcion }}</h6>
            <is-dark-store-canceled-badge :code="estado.codigo" :id="ordenId" />
          </div>
          <small>{{ formattedDate(estado.fecha) }}</small>
        </app-timeline-item>
      </app-timeline>

      <b-button v-if="itCanResetState()" class="full-width mt-1" variant="danger" v-b-modal.modal-1 :disabled="loading">
        <b-spinner v-if="loading" small />
        <small class="m-0" v-else>Reiniciar
          estados
        </small>
      </b-button>

      <!--  <iniciar-preparacion-modal v-if="orderCanStartMake" :orden-id="ordenId" :numero-factura="numeroFactura"
        :tiempo-preparacion="tiempoPreparacion" :callback="callbackOrder" /> -->

      <b-button v-if="orderCanStartMake" class="full-width mt-1" variant="primary" @click="onClickPreparar">
        <small>Iniciar Preparación / Ingresar
          Factura</small></b-button>

      <preparacion-modal :show="showModalPreparar" @hide="hidePreparacionModal" :callback="callbackOrder"
        :orden-id="ordenId" :orden-tiempo-preparacion="tiempoPreparacion" :numero-factura="numeroFactura"
        :current-state="currentState" />

    </b-card>
    <b-modal id="modal-1" title="Atención" @ok="handleOk">
      <p class="my-4">
        Esta acci&oacute;n es <strong style="color:red;">irreversible</strong> y
        reiniciar&aacute; todos los estados de la orden.
        <strong>Desea continuar?</strong>
      </p>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BButton,
  BSpinner,
  BFormGroup,
  BFormRadioGroup
} from "bootstrap-vue";
import moment from "moment";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import PreparacionModal from "./PreparacionModal.vue";
import IniciarPreparacionModal from "./IniciarPreparacionModal.vue";
import IsDarkStoreCanceledBadge from "./IsDarkStoreCanceledBadge.vue";

import {
  getUserID,
  isSuperUser,
  isSupervisor,
  getAuthToken,
  isAdministrator,
} from "@/auth/utils";

export default {
  components: {
    BCard,
    BForm,
    BButton,
    BSpinner,
    BFormGroup,
    AppTimeline,
    AppTimelineItem,
    BFormRadioGroup,
    PreparacionModal,
    IniciarPreparacionModal,
    IsDarkStoreCanceledBadge,
  },
  data() {
    return {
      dataEstados: [],
      loading: false,
      selectedState: null,
      showModalPreparar: false,
      options: [
        { text: "Pendiente", value: "CR" },
        { text: "En preparación / Aprobada", value: "EP" },
        { text: "Preparada", value: "PR" },
        { text: "Enviada / En transporte", value: "EN" },
        { text: "Llego", value: "LL" },
        { text: "No entregada", value: "NE" },
        { text: "Entregada", value: "ET" },
        { text: "Cancelada", value: "AN" }
      ]
    };
  },
  props: {
    ordenId: {
      type: Number,
      required: true
    },
    estadosData: {
      type: Array,
      required: true
    },
    currentState: {
      type: String,
      default: "",
    },
    facturas: {
      type: Array,
      default: [],
    },
    tiempoPreparacion: {
      type: String,
    },
    callbackOrder: {
      type: Function,
      default: () => { },
    }
  },
  methods: {
    itCanResetState() {
      if (this.currentState === "PG") {
        return false;
      }
      return isSupervisor() || isSuperUser() || isAdministrator();
    },
    unsetEstados() {
      var aux = this.dataEstados[0];
      this.dataEstados = [aux];
    },
    sendEliminarEstadosRequest() {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Customer/ReiniciaEstadoOrdenPicking",
          body: JSON.stringify({
            orden_id: this.ordenId,
            empresa: 2,
            cliente_id: getUserID(),
            tokenSesion: getAuthToken()
          })
        })
        .then((res) => {
          if (res.data.bOk) {
            this.callbackOrder();
            this.$toast({
              props: {
                icon: "BellIcon",
                text: `Se reiniciaron los estados del pedido`,
                title: "Éxito"
              },
              component: ToastificationContent
            });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            props: {
              icon: "AlertTriangleIcon",
              text: `Ocurrió un error [${e.message}].`,
              title: "Error",
              variant: "danger"
            },
            component: ToastificationContent
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClickPreparar(e) {
      e && e.preventDefault();
      setTimeout(() => {
        this.showModalPreparar = true;
      }, 100);
    },
    hidePreparacionModal() {
      this.showModalPreparar = false;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.sendEliminarEstadosRequest();
      this.$nextTick(() => {
        this.$bvModal.hide("modal-1");
      });
    },
    formattedDate(originalDate) {
      return moment(new Date(originalDate)).format("DD/MM/YYYY HH:mm a");
    },
    stateIcon(state) {
      switch (state) {
        case "CR":
          return "UserPlusIcon";
        case "PG":
          return "DollarSignIcon";
        case "EP":
          return "PackageIcon";
        case "PR":
          return "ThumbsUpIcon";
        case "EN":
          return "TruckIcon";
        case "LL":
          return "MapPinIcon";
        case "ET":
          return "CheckIcon";
        case "AN":
        case "ND":
        case "NE":
          return "XIcon";
        default:
          return "CheckIcon";
      }
    },
    stateColor(state) {
      switch (state) {
        case "AN":
        case "ND":
        case "NE":
          return "danger";
        default:
          return "success";
      }
    }
  },
  computed: {
    numeroFactura() {
      if (this.facturas.length > 0) {
        return this.facturas[0].factura_empresa_asociada;
      }
      return ""
    },
    orderCanStartMake() {
      if (["PG", "EP", "PR", "EN","LL","NE","ET"].includes(this.currentState)) {
        return true;
      }
      return false;
    },
  },
  beforeMount() {
    this.dataEstados = this.estadosData;
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.custom-radio {
  margin: 10px;
}

.timeline-item:not(:last-of-type) {
  padding-bottom: 1rem !important;
}
</style>
