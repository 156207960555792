var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-iniciarP",
      modifiers: {
        "modal-iniciarP": true
      }
    }],
    staticClass: "full-width mt-1",
    attrs: {
      "variant": "primary"
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _c('small', [_vm._v("Iniciar Preparación / Ingresar Factura")])], 1), _c('b-modal', {
    attrs: {
      "id": "modal-iniciarP",
      "title": "Iniciar preparación",
      "ok-title": "Guardar",
      "cancel-title": "Cancelar"
    },
    on: {
      "ok": _vm.handleOk
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "mb-2 text-center text-bold"
  }, [_vm._v("¿Esta seguro de preparar la orden?")])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v("Ingrese el número de factura")])]), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "3"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "facturaIn1",
      "type": "text",
      "placeholder": "000",
      "formatter": function formatter(s) {
        return _vm.formatter(s, 3);
      }
    },
    model: {
      value: _vm.factura1,
      callback: function callback($$v) {
        _vm.factura1 = $$v;
      },
      expression: "factura1"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "3"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "facturaIn2",
      "type": "text",
      "placeholder": "000",
      "formatter": function formatter(s) {
        return _vm.formatter(s, 3);
      }
    },
    model: {
      value: _vm.factura2,
      callback: function callback($$v) {
        _vm.factura2 = $$v;
      },
      expression: "factura2"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "facturaIn3",
      "type": "text",
      "placeholder": "000000000",
      "formatter": function formatter(s) {
        return _vm.formatter(s, 9);
      }
    },
    model: {
      value: _vm.factura3,
      callback: function callback($$v) {
        _vm.factura3 = $$v;
      },
      expression: "factura3"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mt-2 mb-1"
  }, [_c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0 text-center"
  }, [_vm._v("Tiempo de preparación de la orden")])]), _c('b-col', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('b-badge', {
    attrs: {
      "variant": "dark"
    }
  }, [_c('h4', {
    staticClass: "m-0",
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v(" " + _vm._s(_vm.tiempo) + " Minutos ")])])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }