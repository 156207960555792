<template>
  <!-- si es Eats o OC y no tiene factura -->
  <b-card
    v-if="comercio !== 'MARKET'"
    :title="factura === '' ? 'Preparar la orden': 'Actualizar'"
  >
    <b-alert variant="danger" class="mb-0" :show="true">
      <div 
        class="alert-body"
        v-if="factura === ''"
      >
        <feather-icon icon="InfoIcon" class="mr-50" />
        No has registrado el número de factura
      </div>
    </b-alert>
    <b-button
      block
      class="mt-2"
      @click="showPreparacionModal"
      variant="primary"
    >
      {{ factura === '' ? 'Iniciar Preparacion' : 'Actualizar Factura' }}
    </b-button>
    <preparacion-modal
      :show="showModal"
      :hide="hidePreparacionModal"
      :orden-id="ordenId"
      :callback="callbackOrder"
      :orden-tiempo-preparacion="ordenData.tiempopreparacion"
    />
    <b-modal
      id="modal-factura"
      size="lg"
      title='Actualizar factura'
      v-model="showActFactura"
      centered
      hide-footer
    >
      <factura-modal
        :ordenId="ordenId"
        :factura="factura"
      />
    </b-modal>
    
  </b-card>
  <!-- si es Market y no esta cerrada -->
  <b-card
    title="Anular orden"
    v-else-if="comercio === 'MARKET' && (estado === 'C' || estado === 'IN')"
  >
    <b-alert variant="warning" class="mb-0" :show="true">
      <div class="alert-body">
        <feather-icon icon="InfoIcon" class="mr-50" />
        La orden ya fue pickeada
      </div>
    </b-alert>
    <b-button
      block
      class="mt-2"
      @click="cancelOrder"
      variant="primary"
      :disabled="loading"
    >
      <b-spinner v-if="loading" small label="Loading..." />
      <div v-else>Anular orden</div>
    </b-button>
  </b-card>
</template>

<script>
import { BCard, BAlert, BButton, BSpinner } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import PreparacionModal from "./PreparacionModal.vue";
import FacturaModal from "./FacturaModal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getAuthToken } from "@/auth/utils";

export default {
  components: {
    BCard,
    BAlert,
    BButton,
    BSpinner,
    PreparacionModal,
    ToastificationContent,
    FacturaModal
  },
  props: {
    ordenId: {
      type: Number,
      required: true,
    },
    estado: {
      type: String,
      required: true,
    },
    factura: {
      type: String,
      default: "",
    },
    comercio: {
      type: String,
      required: true,
    },
    callbackOrder: {
      type: Function,
      required: true,
    },
    ordenTiempoPreparacion: {
      type: String,
      default: '0'
    }
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      showModal: false,
      showActFactura: false,
    };
  },
  methods: {
    cancelOrder(event) {
      event && event.preventDefault();
      this.$bvModal
        .msgBoxConfirm("Confirma que desea ANULAR la orden.", {
          size: "sm",
          title: "Confirmar",
          okTitle: "Cerrar",
          centered: true,
          okVariant: "primary",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            this.$http
              .post(this.$store.state.app.middlewareURL, {
                path: "/order/cancel",
                body: JSON.stringify({
                  tokenSesion: getAuthToken(),
                  businessLine: this.comercio,
                  orderId: this.ordenId,
                  enterpriseId: 2,
                }),
              })
              .then((res) => {
                this.loading = false;
                if (res.data.bOk) {
                  this.callbackOrder();
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      icon: "CheckIcon",
                      text: `Orden ${ordenId} anulada.`,
                      title: "Exito",
                      variant: "success",
                    },
                  });
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      icon: "AlertTriangleIcon",
                      text: `Ocurrio un error al cambiar el estado de la orden(${res.data.mensaje}).`,
                      title: "Error",
                      variant: "danger",
                    },
                  });
                }
              })
              .catch((e) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: "AlertTriangleIcon",
                    text: `Ocurrio un error al cambiar el estado de la orden(${e.message}).`,
                    title: "Error",
                    variant: "danger",
                  },
                });
                this.loading = false;
              });
          }
        });
    },
    showPreparacionModal(e) {
      console.log(this.showActFactura)
      if (this.comercio !== 'MARKET' &&  this.factura === ''){
        this.showModal = true
        return
      }
      this.showActFactura = true
    },
    hidePreparacionModal(e) {
      this.showModal = false;
    },
  },
};
</script>

